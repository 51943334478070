.decideapointmentcard {
  margin: auto;
  height: 100vh;
  padding: 1em;
}

.decideapointmentcard img {
  max-width: 90%;
  height: 250px;
}

.img-container {
  position: relative;
}

.img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  transition: 0.5s ease;
  background-color: #008bba46;
}

.img-container:hover .img-overlay {
  opacity: 1;
}

.text {
  color: white;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

@media (max-width: 600px) {
  .img-overlay {
    opacity: 1;
  }
}
