.clients-frame {
  padding-top: 2em;
  padding-bottom: 2em;
  max-width: 100%;
  box-shadow: 0ch;
}

.clients-frame img {
  position: absolute;
  top: 0%;
  z-index: 1;
  left: 0%;
  max-width: 3rem;
}

.secre-dashboard-frame {
  padding: 0px;
}
.secre-dashboard-frame img {
  position: absolute;
  bottom: 11%;
  z-index: 1;
  left: 5%;
  max-width: 5%;
}
