.apointments {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.apointments img {
  position: absolute;
  left: -70%;
  top: -50%;
}

@media (max-width: 600px) {
  .apointments {
    left: 0%;
    margin: 20px;
    transform: translate(0%, -50%);
  }
  .apointments img {
    display: none;
  }
}
