.pet-card {
  padding: 1%;
  margin: 10px;
  border-top: 2px solid var(--primary-color);
  border-bottom: 2px solid var(--secondary-color);
  border-radius: 5%;
  background-color: rgba(213, 243, 243, 0.212);
}

ul {
  list-style: none;
}

.pet-card p {
  margin: 0px;
}

.pet-info {
}
.pet-avatar {
  padding: 5%;
  margin: 10px;
  border-radius: 50%;
}

.pet-avatar img {
  max-width: 25%;
  border-radius: 50%;
}

.pet-avatar p {
  margin: 1px;
}

.pet-avatar a {
  display: inline-block;
  width: 100%;
  color: #fff;
  transform: scale(1);
  transition: 0.2s ease;
}
.pet-avatar a:hover {
  transform: scale(1.2);
  color: red;
}

.pet-avatar button img {
  width: 100%;
}

.pets-bar {
  display: flex;
  list-style: none;
  text-decoration: none;
  padding: 0px;
  margin: 0px;
}

.btn-avatar {
  background-color: white; /* Blue background */
  border: none; /* Remove borders */
  color: white; /* White text */
  padding: 0px; /* Some padding */
  font-size: 35px; /* Set a font size */
  cursor: pointer; /* Mouse pointer on hover */
}

/* Darker background on mouse-over */
.btn-avatar:hover {
  background-color: rgba(150, 175, 250, 0.144);
}

.apointment-card {
  width: 100%;
  box-shadow: -2px 0px 8px 1px rgba(0, 0, 0, 0.199);
  border-radius: 2%;
}

.apointment-card-header {
  display: flex;
  justify-content: space-around;
  background-color: var(--primary-color);
  color: white;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 2%;
  width: 100%;
  line-height: 2px;
  padding: 10px;
}

.apointment-card-info {
  margin-top: -8px;
  /*display: flex;*/
  /*justify-content: space-around;*/
  text-align: center;
  /*align-items: center;*/
  font-style: oblique;

  padding: 2rem;
}

.apointment-card-content {
  margin-top: -8px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-style: oblique;

  padding: 1em;
}

.apointment-card-content img {
  width: 15%;
}

@media (max-width: 600px) {
  .apointment-card-header {
    display: block;
  }
}
