.profile-card {
  box-shadow: 0 0 20px #f1f2ff;
  /* padding: 1em; */
}

.date-detail {
  box-shadow: 0 0 20px #f1f2ff;
  border-radius: 10px;
  border: 1px solid #f1f2ff;
  padding: 1rem 1rem;
  height: auto;
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: "1fr 1fr";
  grid-template-areas:
      "card-title card-title"
      "date-actions date-actions";
}

.date-detail > .details {
  padding-left: 1rem;
}

.date-detail > .info {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  min-width: 250px;
  max-width: 250px;
}

.date-actions {
  grid-area: date-actions;
}

.date-actions button {
  margin: 0em 0.2em;
}

.date-detail p {
  font-size: 15px;
}

.row-bottom-margin {
  margin-bottom: -15px;
}

.date-header {
  display: block;
  background-color: whitesmoke;
  height: auto;
  align-content: center;
  text-align: center;
  margin: 0;
  box-shadow: none;
  display: flex;
  justify-content: space-evenly;
  height: auto;
}

.date-detail p {
  font-size: 15px;
}

.row-bottom-margin {
  margin-bottom: -15px;
}

@media only screen and (max-width: 800px) {
  .date-header {
    display: block;
    background-color: whitesmoke;
    height: auto;
    align-content: center;
    text-align: center;
    margin: 0;
    box-shadow: none;
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    height: auto;
  }
}
