.appointment-card {
    border: 1px solid #ccc;
    border-radius: 10px;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #f9f9f9;
  }
  
  .header {
    margin-bottom: 20px;
  }
  
  .header h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .body {
    font-size: 16px;
  }
  
  .body h3 {
    margin-top: 20px;
    margin-bottom: 10px;
  }
  
  .text-muted {
    color: #6c757d; /* Muted text color */
  }
  