.promotionsPage {
  height: auto;
  min-height: 100vh;
  min-width: 100vw;
  background-color: #deeaf6;
  display: grid;
  grid-template-rows: 15em auto;
  grid-template-columns: repeat(1fr, 12);
  grid-template-areas:
    "tools tools tools tools tools tools tools tools tools tools tools tools"
    "info info info info info info info info info info info info";

  .tools {
    grid-area: tools;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    :nth-child(1n) {
      margin: 0em;
      text-decoration: none;
    }
  }

  .info {
    grid-area: info;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    padding: 2em;
  }
}

.card-button {
  /*background-color: transparent;*/
  z-index: 99;
  height: 5em;
  width: 5em;
  max-height: 5em;
  max-width: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6%;
  box-shadow: 9px 9px 16px #becbd8, -9px -9px 16px #f3f9ff;
  background-color: transparent;
  border: none;
  color: #2d3436;
  position: relative;

  i {
    font-size: 40px;
    z-index: -10;
  }
}

.neumorphism-active {
  position: relative;
  :first-child {
    background: #c31432; /* fallback for old browsers */
    background: -webkit-linear-gradient(
      to right,
      #240b36,
      #c31432
    ); /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(
      to right,
      #240b36,
      #c31432
    ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

.neumorphism-active::after {
  position: absolute;
  padding: 0em;
  margin: 0em;
  content: "";
  width: 4.5em;
  height: 4.5em;
  background: transparent;
  box-shadow: inset 3px 3px 9px #becbd8, inset -3px -3px 9px #f3f9ff;
}
