.calendar-modal {
  position: fixed;
  background: white;
  padding: 2em;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.calendar-modal img {
  position: absolute;
  left: -2rem;
  top: -2rem;
  max-width: 5rem;
}

.calendar-modal i.material-icons {
  position: absolute;
  color: red;
  font-size: 50px;
  z-index: 50;
}
