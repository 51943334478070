.grooming-details {
    font-family: Arial, sans-serif;
    border: none;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin: 20px;
    max-width: 600px;
    background-color: #fff;
}

.image-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
}

.grooming-image {
    width: 45%;
    max-height: 200px;
    object-fit: cover;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.detail {
    margin-bottom: 10px;
}

.label {
    font-weight: bold;
    margin-right: 10px;
}

.value {
    color: #666;
}
