.custom-success-badge {
    color: white;
    background-color: #80a34b;
    padding: 5px 15px;
    border-radius: 5px;
}



/* Apply general styles that are suitable for print */
body {
    font-family: Arial, sans-serif; /* Example font */
    line-height: 1.6; /* Adjust line height for readability */
    font-size: 14px; /* Adjust font size for readability */
}

/* Hide elements that should not be printed */
@media print {
    /* Hide navigation */
    nav {
        display: none;
    }
    
    /* Hide footer with unnecessary links */
    footer {
        display: none;
    }
    
    /* Ensure content takes full page width */
    .content {
        width: 100%;
    }
    
    /* Adjust margins and paddings for better print layout */
    .content,
    .content > * {
        margin: 0;
        padding: 0;
    }
    
    /* Optionally adjust heading styles */
    h1, h2, h3 {
        font-size: 1.5em;
        margin-bottom: 0.5em;
    }
    
    /* Ensure images are scaled appropriately */
    img {
        max-width: 100%;
        height: auto;
    }
    
    /* Page break settings */
    .page-break {
        page-break-after: always;
    }

    #root > div.container-fluid.pet-delivery-status.print-container > div > div > div.row.d-flex.justify-content-center.align-items-center.p-2 > div.col-xl-4.col-lg-4.col-md-12.col-sm-12.h-100,
    #root > div.container-fluid.pet-delivery-status.print-container > div > div > div.row.d-flex.justify-content-center.align-items-center.p-2 > div.col-xl-8.col-lg-8.col-md-12.col-sm-12.print-content > div.row.align-items-center.justify-content-center,
    #wrapper > button,
    #root > div.container-fluid.pet-delivery-status.print-container > div > div > div.row.d-flex.justify-content-center.align-items-center.p-2 > div.col-xl-8.col-lg-8.col-md-12.col-sm-12.print-content > div:nth-child(2) > div:nth-child(1) {
        display: none;
    }

    #root > div.container-fluid.pet-delivery-status.print-container > div > div > div.row.d-flex.justify-content-center.align-items-center.p-2 > div.col-xl-8.col-lg-8.col-md-12.col-sm-12.print-content > div:nth-child(2) > div > div {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .info-printable-card {
        border-radius: 20px;
        border: 1px solid #000;
        margin-bottom: 20px;
        margin-top: 20px;
        padding: 20px;
    }

    #root > div.container-fluid.pet-delivery-status.print-container > div > div > div.row.d-flex.justify-content-center.align-items-center.p-2 > div.col-xl-8.col-lg-8.col-md-12.col-sm-12.print-content,
    #root > div.container-fluid.pet-delivery-status.print-container > div > div > div.row.d-flex.justify-content-center.align-items-center.p-2 > div.col-xl-8.col-lg-8.col-md-12.col-sm-12.print-content > div:nth-child(3) {
        display: flex;
        flex-direction: column;
    }

    #root > div.container-fluid.pet-delivery-status.print-container > div > div > div.row.d-flex.justify-content-center.align-items-center.p-2 > div.col-xl-8.col-lg-8.col-md-12.col-sm-12.print-content > div:nth-child(3) > div:nth-child(2) {
        margin-top: 100px;
    }

    img {
        width: 500px;
        height: auto;
    }

    #root > div.container-fluid.pet-delivery-status.print-container > div > div > div.row.d-flex.justify-content-center.align-items-center.p-2 > div.col-xl-8.col-lg-8.col-md-12.col-sm-12.print-content > div:nth-child(2) > button{
        display: none !important;
    }



    .print-container {
        display: grid;
        grid-template-areas: 
        "print-header"
        "print-content"
        "print-footer";
        grid-template-rows: auto 1fr auto;
        grid-gap: 10px;
    }
}
