@import url("https://fonts.googleapis.com/css?family=Quicksand&display=swap");
*,
*:before,
*:after {
  box-sizing: border-box;
}

:root {
  --primary-color: #4834d4;
  --secondary-color: #686de0;
  --link-size: 64px;
  --trans-props: all 0.3s ease;
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-family: "Quicksand", sans-serif;
  /*background-color: #2d3436;*/
  overflow-x: hidden;
  height: 100vh;
}

h1 {
  font-family: "Quicksand", sans-serif;
}

a.link-unstyled {
  color: inherit;
}

input[type="text"],
input[type="number"],
input {
  font-size: 16px;
}

a.link-unstyled:hover {
  color: inherit;
  text-decoration: none;
}

.btn {
  padding: 0.375rem 2rem;
}

.btn.btn-primary {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--secondary-color);
}

.btn.btn-primary:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: var(--secondary-color);
  border-color: var(--primary-color);
}

.btn.btn-primary:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: var(--primary-color);
  border-color: var(--secondary-color);
}

.btn.btn-primary:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(125, 129, 141, 0.5);
}

.btn.btn-danger {
  color: #fff;
  background-color: #cd4040;
  border-color: #d66060;
}

.btn.btn-danger:not(:disabled):not(.disabled):hover {
  color: #fff;
  background-color: #e02121;
  border-color: #cd4040;
}

.btn.btn-danger:not(:disabled):not(.disabled):active {
  color: #fff;
  background-color: #cd4040;
  border-color: #cd4040;
}

.btn.btn-danger:not(:disabled):not(.disabled):focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 64, 64, 0.5);
}

.bg-light-blue {
  background-color: "#e3f2fd" !important;
}

.loading-box img {
  height: 90%;
  width: 90%;
  position: relative;
}

.on-bottom-corner-right {
  position: absolute;
  left: 56.5%;
  display: flex;
  flex-direction: row;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.178);
}

.modal-main {
  position: fixed;
  background: rgb(233, 231, 231);
  width: 80%;
  max-height: 80vh;
  padding: 2rem;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.modal-main i {
  position: relative;
  color: red !important;
  font-size: 5rem !important;

  left: 92%;
}

.modal-main i:hover {
  cursor: pointer;
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}

i.material-icons {
  position: absolute;
  top: -6.3%;
  right: -2.8%;
  font-size: 60px;
  color: #e02121;
}

.options-container {
  max-height: 3vh;
}

.header-icon {
  max-height: 50px;
}

.testDiv {
  padding-top: 2rem;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-flow: column;
  padding-left: 0rem;
  padding-right: 0rem;
  align-items: center;
  border-radius: 5%;
  padding-bottom: 2rem;
}

@media (max-width: 600px) {
  html {
    font-size: 85%;
  }
}

.overlayloading {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.904);
  z-index: 999;
}

.circle {
  height: 3rem;
  width: 3rem;
  background-color: #fa6900;
  border-radius: 5px;
  position: absolute;
  top: 0rem;
  right: 0rem;
  z-index: 999;
}

.circle:before {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "X"; /* use the hex value here... */
  font-weight: bold;
  font-size: 100%;
  line-height: 3rem;
  text-align: center;
  color: #fff;
}

.ImageTagWithButton {
  position: absolute;
  align-self: center;

  bottom: 0%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(255, 255, 255);
  color: black;
  width: 100%;
  padding: 1rem;
}

/*my own css button*/
.add-button {
  position: absolute;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  top: 0;
  right: 0;
  background-color: #6ab04c;
  border-style: none;
  outline-style: none;
}
.add-button:hover {
  background-color: #badc58;
}
.add-button::before {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  color: #ecf0f1;
  font-size: 2rem;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f067";
}

.add-button:hover::before {
  color: black;
}

.goToAttendAppointment {
  position: relative;
  height: 2rem;
  width: 2rem;
  border-radius: 5%;
  top: 0;
  right: 0;
  background-color: #6ab04c;
  border-style: none;
  outline-style: none;
}
.goToAttendAppointment:hover {
  background-color: #badc58;
}
.goToAttendAppointment::before {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 5%;
  color: #ecf0f1;
  font-size: 1.3rem;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f274";
}

.goToAttendAppointment:hover::before {
  color: black;
}

.dontShow {
  position: relative;
  height: 2rem;
  width: 2rem;
  border-radius: 5%;
  top: 0;
  right: 0;
  background-color: purple;
  border-style: none;
  outline-style: none;
}
.dontShow:hover {
  background-color: white;
  color: purple;
}
.dontShow::before {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 5%;
  color: #ecf0f1;
  font-size: 1.3rem;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f783";
}

.dontShow:hover::before {
  color: purple;
}

.changeDate {
  position: relative;
  height: 2rem;
  width: 2rem;
  border-radius: 5%;
  background-color: #d35400;
  border-style: none;
  outline-style: none;
}
.changeDate:hover {
  background-color: #e67e22;
}
.changeDate::before {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 5%;
  color: #ecf0f1;
  font-size: 1.3rem;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f273";
}

.changeDate:hover::before {
  color: black;
}

.delete {
  position: relative;
  height: 2rem;
  width: 2rem;
  border-radius: 5%;
  top: 0;
  background-color: #c0392b;
  border-style: none;
  outline-style: none;
}
.delete:hover {
  background-color: #e74c3c;
}
.delete::before {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 5%;
  color: #ecf0f1;
  font-size: 1.3rem;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f2ed";
}

.delete:hover::before {
  color: black;
}

@media (max-width: 600px) {
  #AdminDashboard .add-button {
    top: -31rem;
    left: 0;
  }
}

.showHistoryButton {
  position: absolute;
  height: 2rem;
  width: 2rem;
  border-radius: 5%;
  top: 1rem;
  right: 1rem;
  background-color: #b2bec3;
  border-style: none;
  outline-style: none;
}
.showHistoryButton:hover {
  background-color: #b2bec3;
}

.showHistoryButton:hover::before {
  content: "\f07c" !important;
}
.showHistoryButton::before {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 5%;
  color: #002129;
  font-size: 1.3rem;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f07b";
}

.getBack {
  position: absolute;
  height: 2rem;
  width: 2rem;
  border-radius: 5%;
  top: 0;
  right: 0%;
  background-color: #8e44ad;
  border-style: none;
  outline-style: none;
}
.getBack:hover {
  background-color: #9b59b6;
}
.getBack::before {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  border-radius: 5%;
  color: #ecf0f1;
  font-size: 1.3rem;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f30a";
}

.getBack:hover::before {
  color: black;
}

.login {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 0em 1em 0em;
  background: #e0e5ec;
}

.register {
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 0em 1em 0em;
  background: #e0e5ec;
}

.register .card {
  background-color: #e0e5ec;
  border: none;
}

@media (min-width: 600px) {
  .login .card {
    max-width: 35%;
  }
}

.neumorphism-1 {
  background: #e0e5ec;
  border: none;
  box-shadow: 9px 9px 16px rgb(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5);
}

.login .card form .form-group input {
  background-color: #e0e5ec;
  border: none;
  outline: none;
  border-radius: 40px;
  color: black;
  box-shadow: inset 3px 3px 9px rgb(163, 177, 198, 0.6),
    inset -3px -3px 9px rgba(255, 255, 255, 0.5);
}

.login .card form p a {
  color: rgb(5, 59, 129);
}

.login .card form .form-group button {
  background-color: #e0e5ec;
  color: black;
  border: none;
  border-radius: 40px;
  box-shadow: 9px 9px 16px rgb(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5);
  -webkit-transition: box-shadow 2s ease-out;
  -moz-transition: box-shadow 2s ease-out;
  -o-transition: box-shadow 2s ease-out;
  transition: box-shadow 2s ease-out;
}

.login .card form .form-group button:not(:disabled):not(.disabled):hover {
  color: black;
  background-color: #e0e5ec;
}

.login .card form .form-group button:not(:disabled):not(.disabled):active {
  color: black;
  background-color: #e0e5ec;
  box-shadow: inset 3px 3px 9px rgb(163, 177, 198, 0.6),
    inset -3px -3px 9px rgba(255, 255, 255, 0.5);
}

.login .card form .form-group button:not(:disabled):not(.disabled):focus {
  box-shadow: inset 3px 3px 9px rgb(163, 177, 198, 0.6),
    inset -3px -3px 9px rgba(255, 255, 255, 0.5);
}

.register .card form .form-group input {
  background-color: #e0e5ec;
  border: none;
  outline: none;
  border-radius: 40px;
  color: black;
  box-shadow: inset 3px 3px 9px rgb(163, 177, 198, 0.6),
    inset -3px -3px 9px rgba(255, 255, 255, 0.5);
}

.register .card form p a {
  color: rgb(5, 59, 129);
}

.register .card form .form-group button {
  background-color: #e0e5ec;
  color: black;
  border: none;
  border-radius: 40px;
  box-shadow: 9px 9px 16px rgb(163, 177, 198, 0.6),
    -9px -9px 16px rgba(255, 255, 255, 0.5);
  -webkit-transition: box-shadow 2s ease-out;
  -moz-transition: box-shadow 2s ease-out;
  -o-transition: box-shadow 2s ease-out;
  transition: box-shadow 2s ease-out;
}

.register .card form .form-group button:not(:disabled):not(.disabled):hover {
  color: black;
  background-color: #e0e5ec;
}

.register .card form .form-group button:not(:disabled):not(.disabled):active {
  color: black;
  background-color: #e0e5ec;
  box-shadow: inset 3px 3px 9px rgb(163, 177, 198, 0.6),
    inset -3px -3px 9px rgba(255, 255, 255, 0.5);
}

.register .card form .form-group button:not(:disabled):not(.disabled):focus {
  box-shadow: inset 3px 3px 9px rgb(163, 177, 198, 0.6),
    inset -3px -3px 9px rgba(255, 255, 255, 0.5);
}

.sidebar {
  width: 7em;
  height: 100vh;
  overflow-x: hidden;
  display: grid;
  grid-template-columns: 100%;
  grid-template-rows: 15vh auto auto;
  grid-template-areas:
    "brand"
    "sidebar-nav"
    "social";
  background-color: #2d3436;
  position: relative;
  transition: all 0.2s ease-in;
}

.sidebar:hover {
  min-width: 15em;
}

.sidebar:hover .sidebar-nav ul li:hover i {
  color: #2d3436;
}

.brand {
  grid-area: brand;
  display: flex;
  color: white;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.sidebar-nav {
  grid-area: sidebar-nav;
}
.sidebar-nav ul {
  width: 100%;
  padding-left: 0;
}

.fas,
far {
  font-size: 1.4em;
}

.sidebar-nav ul li {
  display: flex;
  position: relative;
  align-items: center;
  flex-wrap: nowrap;
  color: white;
  height: 3.2rem;
}

.sidebar-nav ul li i {
  min-width: 3em;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-nav ul li:hover {
  background-color: #ededed;
}
.sidebar-nav ul li:hover::after {
  position: absolute;
  bottom: 100%;
  right: 0%;
  content: "";
  width: 0;
  height: 0;
  border-bottom: 1em solid #ededed;
  border-left: 1em solid transparent;
}
.sidebar-nav ul li:hover::before {
  position: absolute;
  top: 100%;
  right: 0%;
  content: "";
  width: 0;
  height: 0;
  border-top: 1em solid #ededed;
  border-left: 1em solid transparent;
}

.sidebar-nav ul > li a {
  color: white;
  text-decoration: none;
}

.sidebar-nav ul > li:hover a {
  color: #2d3436;
  z-index: 2;
}

.main-app-content {
  background-color: #ededed;

  /*margin-right: 2em;*/
}

.btn-neuphomorfism {
  text-align: center;
  height: 2em;
  width: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  outline: none;
  border: none !important;
  box-shadow: 3px 3px 16px rgb(0, 0, 0, 0.3),
    -3px -3px 16px rgba(255, 255, 255, 0.2);
  background-color: #2d3436;
  color: red;
}

.btn-neuphomorfism:not(:disabled):not(.disabled):hover {
  color: black;
  background-color: red;
}

.btn-neuphomorfism:not(:disabled):not(.disabled):active {
  color: black;
  box-shadow: inset 3px 3px 16px rgb(0, 0, 0, 0.3),
    inset -3px -3px 16px rgba(255, 255, 255, 0.2);
}

.btn-neuphomorfism:not(:disabled):not(.disabled):focus {
  outline: none;
  box-shadow: inset 3px 3px 16px rgb(0, 0, 0, 0.3),
    inset -3px -3px 16px rgba(255, 255, 255, 0.2);
}
