#HeaderPetContainer {
  max-width: 100%;
  margin-top: -8%;
  margin-bottom: -2.2%;
  z-index: -10;
}

@media (min-width: 700px) {
  #HeaderPetContainer {
    max-width: 100%;
    margin-top: -15%;
    margin-bottom: -2.2%;
    z-index: -5;
  }
}
