.date-header {
  display: block;
  background-color: rgba(187, 183, 183, 0.068);
  height: 10vh;
  align-content: center;
  text-align: center;
  margin: 0;
  margin-bottom: 25px;
  margin-top: 15px;
  box-shadow: 0px 10px 4px 4px #f0f2f5;
  height: auto;
}

.step {
  display: flex;
  margin-bottom: 5%;
}
.step-span {
  background: red;
  height: 2em;
  width: 2em;
  border-radius: 50%;
}

.social-icons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: row wrap;
  margin-bottom: 3%;
}
.social-icons a {
  text-decoration: none;
}

/*** Containers and icons ***/

.social-icon {
  display: flex;

  position: relative;
  overflow: hidden; /* Trim branded overlays */

  width: var(--link-size);
  height: var(--link-size);
  margin: 8px;

  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.12);

  text-decoration: none;

  transition: var(--trans-props);
}

.social-icon i {
  margin: auto; /* Perfect centering thanks to parental flex */

  font-size: 24px;
  color: hsl(27, 49%, 50%);

  z-index: 1; /* Make sure icons will be on top */

  transition: var(--trans-props);
}

/*** Branded overlays from the bottom ***/

.social-icon:after {
  content: "";

  width: var(--link-size);
  height: var(--link-size);

  position: absolute;

  transform: translate(0, var(--link-size));

  border-radius: 50%;
  transition: var(--trans-props);
}

.social-icon.twitter:after {
  background-color: #1da1f2;
}

.social-icon.github:after {
  background-color: #24292e;
}

.social-icon.dribbble:after {
  background-color: #ea4c89;
}

.social-icon.instagram:after {
  background-color: #e01183;
}

.social-icon.behance:after {
  background-color: #0004ff;
}

.social-icon.medium:after {
  background-color: #00ab6c;
}

/*** Animations ***/

.social-icon:hover {
  transform: translateY(-4px);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.16);
}

.social-icon:hover i {
  /* 	transform: scale(1.1); */
  color: #fff;
}

.social-icon:hover:after {
  transform: translate(0) scale(1.2);
}

.step-form {
  margin: auto;
  height: 100vh;
  padding: 5em;
}
